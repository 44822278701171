import {Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle} from "~/components/ui/card";
import {Form} from "@remix-run/react";
import {Label} from "~/components/ui/label";
import {Input} from "~/components/ui/input";
import {Button} from "~/components/ui/button";

export default function LoginForm({ description, hasError }) {
    return (
        <Card className="w-full max-w-lg p-0 text-white p-5">
            <CardHeader>
                <CardTitle className="text-3xl text-center logo">Forbi</CardTitle>
                {description && <CardDescription className="text-center text-white">
                    {description}
                </CardDescription>}
            </CardHeader>
            <Form method="post">
                <CardContent className="grid gap-4">
                    <div className="grid gap-2">
                        <Label htmlFor="email" className="font-light text-sm">Email</Label>
                        <Input className={hasError ? 'border-destructive' : ''} name="email" id="email" type="email" required />
                    </div>
                    <div className="grid gap-2">
                        <Label htmlFor="password" className="font-light">Пароль</Label>
                        <Input className={hasError ? 'border-destructive' : ''} name="password" id="password" type="password" required />
                    </div>

                    {hasError && <CardDescription className="text-destructive">
                        Неверные Email или Пароль
                    </CardDescription>}
                </CardContent>
                <CardFooter>
                    <Button className="w-full h-14 rounded-sm bg-[#5C5ACF]">Войти</Button>
                </CardFooter>
            </Form>
        </Card>
    );
}